<template>
  <div
    class="pathadvice-lead-form pathadvice-lead-form--chat pathadvice-lead-form--mobile"
    :style="{
      '--contact-form-font-color': dialog.contactForm.fontColor,
      '--contact-form-background': dialog.contactForm.isDefaultContactFormInsideChat ? '#EFF1F5' : toolBarBackground,
      '--contact-form-darken-background': darkСontactFormSendButtonBackgroundColor,
      '--contact-form-dark-background': darkСontactFormSendButtonBackgroundColor
    }"
  >
    <div class="mt-0 pathadvice-lead-form__fields">
      <div>
        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('name')
          }"
        >
          <input
            name="name"
            key="name"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$t('vue.name') | modifyInputPlaceholder(true)"
            v-model="name"
            v-validate="{
              required: true,
              max: 80
            }"
          />

          <p
            v-if="errors.first('name')"
            class="pathadvice__contact-form__main-container__form__field__error-checkbox"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('name') }}
          </p>

          <UserIcon class="pathadvice__contact-form__main-container__form__field__icon" />
        </div>

        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('email')
          }"
        >
          <input
            name="email"
            key="email"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$t('vue.emailAddress') | modifyInputPlaceholder(true)"
            v-model="email"
            v-validate="{
              required: true,
              email: true
            }"
          />

          <p
            v-if="errors.first('email')"
            class="pathadvice__contact-form__main-container__form__field__error"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('email') }}
          </p>

          <MailIcon class="pathadvice__contact-form__main-container__form__field__icon" />
        </div>

        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('phone')
          }"
        >
          <input
            name="phone"
            key="phone"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$t('vue.phone') | modifyInputPlaceholder(false)"
            v-model="phone"
            v-validate="{
              max: 15
            }"
          />

          <p
            v-if="errors.first('phone')"
            class="pathadvice__contact-form__main-container__form__field__error"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('phone') }}
          </p>

          <VerticalPhoneIcon class="pathadvice__contact-form__main-container__form__field__icon" />
        </div>

        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('country')
          }"
        >
          <input
            name="country"
            key="country"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$t('vue.country') | modifyInputPlaceholder(false)"
            v-model="countryName"
            v-validate="{
              max: 50
            }"
          />

          <p
            v-if="errors.first('country')"
            class="pathadvice__contact-form__main-container__form__field__error"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('country') }}
          </p>

          <FlagIcon class="pathadvice__contact-form__main-container__form__field__icon" />
        </div>

        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('company')
          }"
        >
          <input
            name="company"
            key="company"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$t('vue.company') | modifyInputPlaceholder(false)"
            v-model="companyName"
            v-validate="{
              max: 250
            }"
          />

          <p
            v-if="errors.first('company')"
            class="pathadvice__contact-form__main-container__form__field__error"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('company') }}
          </p>

          <CompanyIcon class="pathadvice__contact-form__main-container__form__field__icon" />
        </div>
      </div>

      <div class="pathadvice__contact-form__main-container__form__actions pathadvice__contact-form__main-container__form__footer">
        <button
          class="pathadvice__contact-form__main-container__form__actions__btn"
          :disabled="isLoadingSubmit"
          :style="`background: ${
            dialog.contactForm.isDefaultContactFormInsideChat ? '#494A4E' : darkСontactFormSendButtonBackgroundColor
          }; color: #fff !important`"
          @click.prevent="submit"
        >
          {{ $t('vue.send') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import 'firebase/firestore'

import sanitizeHtml from 'sanitize-html'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

import { mapGetters } from 'vuex'

import UserIcon from '../../../components/icons/UserIcon'
import MailIcon from '../../../components/icons/MailIcon'
import VerticalPhoneIcon from '../../../components/icons/VerticalPhoneIcon'
import FlagIcon from '../../../components/icons/FlagIcon.vue'
import CompanyIcon from '../../../components/icons/CompanyIcon.vue'

export default {
  name: 'LeadForm',

  components: {
    UserIcon,
    MailIcon,
    VerticalPhoneIcon,
    FlagIcon,
    CompanyIcon
  },

  props: {
    dialog: {
      type: Object
    },
    modifiedMessages: {
      type: Array
    },
    activeLeadFormId: {
      type: String
    }
  },

  data: () => ({
    isLoadingSubmit: false,
    name: '',
    email: '',
    phone: '',
    countryName: '',
    companyName: '',
    stateVisitorId: '',
    stateVisitor: {},
    agentInfo: null
  }),

  computed: {
    ...mapGetters({
      visitorIdProps: 'webrtc/visitorId',
      visitorProps: 'webrtc/visitor',
      company: 'company'
    }),

    visitorId() {
      return this.visitor ? this.visitor.id : ''
    },

    toolBarBackground() {
      return this.dialog.step2ButtonColor || '#3B86F7'
    },

    darkСontactFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.toolBarBackground, -40)
    }
  },

  created() {
    this.stateVisitorId = JSON.parse(JSON.stringify(this.visitorIdProps))
    this.stateVisitor = JSON.parse(JSON.stringify(this.visitorProps))

    this.setAgentInfo()
  },

  methods: {
    sanitizeHtml,

    setAgentInfo() {
      if (this.stateVisitor && this.stateVisitor.connectedAgent && this.stateVisitor.dialogCompanyName && this.stateVisitor.connectedAgentId) {
        const { connectedAgent, dialogCompanyName, connectedAgentImage, connectedAgentId } = this.stateVisitor

        this.agentInfo = {
          connectedAgent,
          dialogCompanyName,
          connectedAgentImage,
          connectedAgentId,
          companyDisplayName: this.company && this.company.companyDisplayName ? this.company.companyDisplayName : this.company.name
        }
      }
    },

    filteredMessages() {
      if (this.modifiedMessages && this.modifiedMessages.length) {
        const filteredByVisitor = this.modifiedMessages.filter((el) => el.sender === 'visitor' && (!el.id || (el.id && el.leadForm)))

        return filteredByVisitor
      }

      return []
    },

    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    },

    async sendMessageRQ(msg = '', sender = 'visitor') {
      try {
        const leadForm = {
          name: this.name,
          email: this.email
        }

        if (this.phone) {
          leadForm.phone = this.phone
        }

        const message = {
          sender,
          message: this.sanitizeHtml(msg),
          time: new Date(),
          chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
          type: 'lead-form',
          leadForm
        }

        if (this.agentInfo && this.agentInfo.connectedAgentId) {
          message.agentInfo = this.agentInfo
        }

        const visitorUpdate = {
          visitorDisplayName: this.name,
          shouldNotifyAgents: false,
          chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
          callTypeState: 'visitor-lead-form-filling',
          visitorMessages: this.$firebase.firestore.FieldValue.delete(),
          agentMessages: this.$firebase.firestore.FieldValue.increment(1),
          activeLeadFormId: this.$firebase.firestore.FieldValue.delete(),
          isPreparedLeadFormToBeSent: true
        }

        await this.$db.collection('visitors').doc(this.stateVisitorId).update(visitorUpdate)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },

    async submit() {
      const result = await this.$validator.validateAll()

      if (!result) {
        return
      }

      this.isLoadingSubmit = true

      try {
        await this.sendMessageRQ()
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      this.isLoadingSubmit = false
    }
  },

  filters: {
    modifyInputPlaceholder(string, isRequired) {
      return isRequired ? `${string}*` : string
    }
  }
}
</script>

<style lang="scss" scoped>
.pathadvice {
  &__contact-form {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 13;

    &__main-container {
      &__form {
        &__field {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top: 10px;
          width: 100%;
          text-align: left;

          &:first-child {
            margin-top: 0;
          }

          &_invalid {
            border-radius: 6px;

            .pathadvice__contact-form__main-container__form {
              &__input {
                border-color: rgb(240, 85, 65);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }

          &__error {
            padding: 0 14px;
            font-size: 13px;
            line-height: 16px;
            background: rgb(240, 85, 65);
            border-bottom-left-radius: 13px;
            border-bottom-right-radius: 13px;
          }

          &__error-checkbox {
            padding: 0 14px;
            font-size: 13px;
            line-height: 16px;
            background: rgb(240, 85, 65);
          }

          &__icon {
            position: absolute;
            top: 16px;
            left: 15px;
            width: 15px;
            height: 15px;
            color: #262629;
            stroke: #262629;

            &-action {
              position: absolute;
              top: 17px;
              right: 16px;
            }
          }
        }

        &__input {
          padding: 11px 11px 11px 43px;
          width: 100%;
          color: #262629;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          background: rgba(230, 236, 241, 0.6);
          border: 2px solid transparent;
          border-radius: 6px;
          outline: none;
          font-family: 'Lato', sans-serif;

          &::placeholder {
            color: #262629;
            font-family: 'Lato', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 5000s;
            -webkit-text-fill-color: var(--contact-form-darken-background) !important;
          }
        }

        &__footer {
          margin: 10px 0 0;
          font-size: 14px;
        }

        &__actions {
          &__btn {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 10px;
            color: inherit;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;

            background: var(--contact-form-darken-background);
            border-radius: 6px;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    width: 375px;
  }
}

.pathadvice-lead-form {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: var(--contact-form-background);
  border-radius: 5px;
  overflow: hidden;

  &--chat {
    display: block;
    position: static;
    padding: 20px 12px;
  }

  &__fields {
    margin-top: 24px;
  }
}

.pathadvice__contact-form__main-container__form {
  &__actions {
    &__btn {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 12px 10px;
      color: inherit;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      box-shadow: 0px 2.79165px 6.97912px rgba(0, 0, 0, 0.5);
      border-radius: 14px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>
