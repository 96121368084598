<template>
  <div :class="screen === SCREEN.MAXIMIZED ? 'video-audio-chat-video-visitor-maximized' : ''">
    <div
      class="flex video-audio-chat-video-container"
      :class="{
        'video-audio-chat-video-container--mobile': isMobile
      }"
    >
      <!-- Visitor Video Placeholder -->
      <div
        :class="{
          'video-audio-chat-video-visitor-video-minimized': screen === SCREEN.MINIMIZED,
          'video-audio-chat-video-visitor-video-maximized ': screen !== SCREEN.MINIMIZED
        }"
      >
        <div
          class="video-audio-chat-audio"
          v-show="!visitorVideoOn || IS_VISITOR_SCREEN_SHARING"
          :class="{
            'video-audio-chat-audio-maximized': screen === SCREEN.MAXIMIZED,
            'video-audio-chat-audio-minimized': screen !== SCREEN.MAXIMIZED
          }"
          :style="isMobile ? `width: 100%; height: ${videoAudioHeight}px !important;` : ''"
        >
          <div class="video-audio-chat-audio-header flex flex-col justify-center">
            <div v-if="visitorAudioOn && !remoteAudioReady && !IS_VISITOR_SCREEN_SHARING" class="video-audio-chat-typing-indicator">
              <span style="background-color: rgba(255, 255, 255, 0.6)"></span>
              <span style="background-color: rgba(255, 255, 255, 0.8)"></span>
              <span style="background-color: rgba(255, 255, 255, 1)"></span>
            </div>

            <audio-video-muted-icon v-if="isRemoteAudioVideoMuted" :width="remoteVideoMutedWidth" :height="remoteVideoMutedHeight"></audio-video-muted-icon>
            <audio-icon :width="199" :height="79" v-else-if="visitorAudioOn"></audio-icon>

            <div v-if="!visitorAudioOn && !isRemoteAudioVideoMuted" class="video-audio-chat-muted-icon">
              <audio-crossed-icon :height="20" :width="20"></audio-crossed-icon>
            </div>
          </div>
        </div>

        <div
          v-show="visitorVideoOn && !IS_VISITOR_SCREEN_SHARING"
          class="video-audio-chat-video__wrapper"
          :style="isMobile ? `width: 100%; height: ${videoAudioHeight}px !important;` : ''"
        >
          <div class="video-audio-chat-audio-header flex flex-col justify-center">
            <div v-if="visitorVideoOn && !remoteVideoReady" class="video-audio-chat-typing-indicator">
              <span style="background-color: rgba(255, 255, 255, 0.6)"></span>
              <span style="background-color: rgba(255, 255, 255, 0.8)"></span>
              <span style="background-color: rgba(255, 255, 255, 1)"></span>
            </div>
            <video ref="remoteVideo" id="remote-video" autoplay playsinline />

            <div v-if="!visitorAudioOn" class="video-audio-chat-muted-icon">
              <audio-crossed-icon :height="20" :width="20"></audio-crossed-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- Agent Video Placeholder -->
      <div
        :class="
          screen === SCREEN.MINIMIZED
            ? `video-audio-chat-video-agent-video-minimized ${
                isMobile && messageModes.includes('chat') ? 'video-audio-chat-video-agent-video-minimized--moved' : ''
              }`
            : 'video-audio-chat-video-agent-video-maximized mt-2 mb-2 ml-4 mr-4'
        "
      >
        <div
          class="video-audio-chat-local-audio"
          :class="screen === SCREEN.MINIMIZED ? 'video-audio-chat-local-audio-small' : ''"
          v-show="!showLocalVideo && showRemoteVideo"
        >
          <div class="video-audio-chat-local-audio-header">
            <div>
              <audio-video-muted-icon v-if="isLocalAudioVideoMuted" :width="localVideoMutedWidth" :height="localVideoMutedHeight"></audio-video-muted-icon>
              <audio-icon v-else :width="localVideoMutedWidth" :height="localVideoMutedHeight"></audio-icon>
            </div>
          </div>
        </div>
        <video
          ref="localVideo"
          style="opacity: 1; width: 100%; height: 100%; position: absolute"
          id="local-video"
          class="video"
          width="300"
          height="400"
          muted
          autoplay
          playsinline
        ></video>

        <canvas
          :class="screen === SCREEN.MINIMIZED ? 'video-audio-chat-local-audio-small' : 'video-audio-chat-video-visitor-video-maximized'"
          style="pointer-events: none; z-index: 58000; top: 0px; right: 0px; opacity: 0; object-fit: cover; border-radius: 6px"
          width="1280"
          height="720"
          id="output_canvas"
        ></canvas>
      </div>
    </div>

    <button v-if="isSwitchButton" class="video-audio-chat__button-switch" @click.prevent="switchCamera">
      <camera-switch-icon @click="switchCamera" />
    </button>

    <visitor-chats
      class="video-audio-chat-chat-messages"
      v-if="screen === SCREEN.MAXIMIZED"
      :screen="screen"
      :cobrowse-status="cobrowseStatus"
      :call-recording-status="callRecordingStatus"
    ></visitor-chats>
  </div>
</template>
<script>
const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}
import { mapGetters } from 'vuex'
import VisitorChats from './VisitorChats.vue'

import AudioIcon from '../../../components/icons/AudioIcon.vue'
import AudioCrossedIcon from '../../../components/icons/AudioCrossedIcon'
import AudioVideoMutedIcon from '../../../components/icons/AudioVideoMutedIcon.vue'
import CameraSwitchIcon from '../../../components/icons/CameraSwitchIcon.vue'

export default {
  props: {
    screen: {
      type: String,
      required: true
    },
    localStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    remoteStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: true
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    cobrowseStatus: {
      type: String,
      required: false
    },
    callRecordingStatus: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    isSwitchButton: {
      type: Boolean,
      required: false
    },
    cameraOptions: {
      type: Array,
      required: false
    }
  },
  components: {
    VisitorChats,
    AudioIcon,
    AudioCrossedIcon,
    AudioVideoMutedIcon,
    CameraSwitchIcon
  },
  data() {
    return {
      SCREEN,
      localVideoStreamCount: 0,
      remoteVideoStreamCount: 0,
      showRemoteVideo: true,
      remoteAudioReady: false,
      remoteVideoReady: false,
      videoAudioHeight: 0
    }
  },
  created() {
    this.setAudioVideoHeight()
    this.checkVisitorMediaReadiness()

    this.$serverBus.$on('visitor-media-state', (data) => {
      // /* This information is used for Bigquery to determine if the call was upgraded to video/audio */
      if (
        data.visitorVideoOn &&
        this.visitorId &&
        this.visitor.agentRequestTypeUpdated !== 'video' &&
        (this.visitor.agentRequestType === 'chat' || this.visitor.agentRequestType === 'audio')
      ) {
        this.$db.collection('visitors').doc(this.visitorId).set({ callTypeState: 'call-upgraded', agentRequestTypeUpdated: 'video' }, { merge: true })
      }

      if (data.visitorAudioOn && this.visitorId && this.visitor && !this.visitor.agentRequestTypeUpdated && this.visitor.agentRequestType === 'chat') {
        this.$db.collection('visitors').doc(this.visitorId).set({ callTypeState: 'call-upgraded', agentRequestTypeUpdated: 'audio' }, { merge: true })
      }
    })

    this.$serverBus.$on('visitor-media-track-state', () => {
      setTimeout(() => {
        this.checkVisitorMediaReadiness()
      }, 500)
    })
  },
  mounted() {
    if (!this.messageModes.includes('video')) {
      this.$refs.localVideo.srcObject = null
      this.$refs.remoteVideo.srcObject = null
    }

    if (this.selectedSpeaker && this.selectedSpeaker.value) {
      this.attachSinkId(this.selectedSpeaker.value)
    }

    if (this.messageModes.includes('video')) {
      if (this.videoStream) {
        this.$refs.localVideo.srcObject = this.videoStream
        this.localVideoStreamCount = this.videoStream.getVideoTracks().filter((x) => x.enabled).length
      }
      if (this.remoteStream) {
        this.$refs.remoteVideo.srcObject = this.remoteStream
        this.remoteVideoStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('visitor-media-state')
    this.$serverBus.$off('visitor-media-track-state')
  },
  watch: {
    localStream() {
      if (this.localStream) {
        this.$refs.localVideo.srcObject = this.videoStream
        this.localVideoStreamCount = this.videoStream.getVideoTracks().filter((x) => x.enabled).length
      }
    },
    remoteStream() {
      if (this.remoteStream) {
        this.$refs.remoteVideo.srcObject = this.remoteStream
        this.remoteVideoStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
    },
    messageModes() {
      if (this.videoStream) {
        this.$refs.localVideo.srcObject = this.videoStream
        this.localVideoStreamCount = this.videoStream.getVideoTracks().filter((x) => x.enabled).length
      }
      if (this.remoteStream) {
        this.remoteVideoStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
    },
    'selectedSpeaker.value'() {
      if (this.selectedSpeaker.value) {
        this.attachSinkId(this.selectedSpeaker.value)
      }
    },
    visitorVideoOn() {
      if (this.visitorVideoOn) {
        this.checkVisitorMediaReadiness()
      }
    },
    visitorAudioOn() {
      if (this.visitorAudioOn) {
        this.checkVisitorMediaReadiness()
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      cameraStream: 'webrtc/cameraStream',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      messageModes: 'webrtc/messageModes',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor',
      visitorVideoOn: 'webrtc/visitorVideoOn',
      visitorAudioOn: 'webrtc/visitorAudioOn'
    }),
    videoStream() {
      return this.cameraStream || this.localStream
    },
    IS_VISITOR_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.initiatedBy === 'visitor' &&
        this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING
      )
    },
    hasRemoteAudioOrVideoStream() {
      let result = false
      if (this.remoteStream) {
        result = this.remoteStream.getVideoTracks().length > 0
        if (!result) {
          result = this.remoteStream.getAudioTracks().length > 0
        }
      }
      return result
    },
    hasVideoStream() {
      const hasVideoStream = this.localVideoStreamCount > 0 || this.remoteVideoStreamCount > 0
      return hasVideoStream
    },
    hideAudioStyle() {
      if (this.hasVideoStream) {
        return {
          visibility: 'hidden'
        }
      } else {
        return {
          visibility: 'visible'
        }
      }
    },
    hideVideoStyle() {
      if (this.hasVideoStream) {
        return {
          visibility: 'visible'
        }
      } else {
        return {
          visibility: 'hidden'
        }
      }
    },
    showLocalVideo() {
      return this.messageModes.includes('video')
    },
    isLocalAudioVideoMuted() {
      return !this.messageModes.includes('video') && !this.messageModes.includes('audio')
    },
    isRemoteAudioVideoMuted() {
      return !this.visitorVideoOn && !this.visitorAudioOn
    },
    remoteVideoMutedHeight() {
      return this.screen === this.SCREEN.MINIMIZED ? 79 : 100
    },
    remoteVideoMutedWidth() {
      return this.screen === this.SCREEN.MINIMIZED ? 199 : 500
    },
    localVideoMutedHeight() {
      return this.screen === this.SCREEN.MINIMIZED ? 28 : 100
    },
    localVideoMutedWidth() {
      return this.screen === this.SCREEN.MINIMIZED ? 67 : 500
    }
  },
  methods: {
    async switchCamera() {
      const switchedCamera = this.cameraOptions.find((item) => (this.selectedVideo ? item.value !== this.selectedVideo.value : true))

      if (switchedCamera) {
        this.$serverBus.$emit('video-input-selected', switchedCamera)
      }
    },
    setAudioVideoHeight() {
      const clientScreenHeight = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight

      this.videoAudioHeight = clientScreenHeight - 80 - 100
    },

    checkVisitorMediaReadiness() {
      if (this.remoteStream && this.remoteStream.getVideoTracks()) {
        this.remoteVideoReady = Boolean(
          this.remoteStream && this.remoteStream.getVideoTracks() && this.remoteStream.getVideoTracks().filter((x) => x.enabled).length > 0
        )
      }
      if (this.remoteStream && this.remoteStream.getAudioTracks()) {
        this.remoteAudioReady = Boolean(
          this.remoteStream && this.remoteStream.getAudioTracks() && this.remoteStream.getAudioTracks().filter((x) => x.enabled).length > 0
        )
      }
    },
    async attachSinkId(sinkId) {
      if (this.$refs.remoteVideo && this.$refs.remoteVideo.setSinkId) {
        await this.$refs.remoteVideo.setSinkId(sinkId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes typing {
  50% {
    opacity: 1;
    transform: scale(1.25);
  }
}
.video-audio-chat {
  &-muted-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 15px;
    bottom: 15px;
    width: 35px;
    height: 35px;
    background: rgba(230, 236, 241, 0.7);
    border-radius: 4px;
    z-index: 98;
  }

  .video {
    height: 100% !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
  }

  &-typing-indicator {
    background-color: #275D73 !important;
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    animation: 1s visible;
    span {
      height: 10px;
      width: 10px;
      float: left;
      margin: 0 5px;
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation: 1s typing infinite ($i * 0.3333s);
        }
      }
    }
  }

  &-video {
    &-container {
      margin: auto;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 98%;
      max-width: 100%;
      max-height: 98%;
      position: relative;
      @media only screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
      }
      @media only screen and (min-width: 1025px) {
        display: flex;
        flex-direction: row;
      }
    }

    &__wrapper {
      display: flex;
      position: relative;
      overflow: hidden;

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &-visitor {
      &-maximized {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-evenly;
        align-items: center;
        padding: 0px;
        height: 98%;
        width: 100%;
      }
      &-video {
        &-minimized {
          background-color: rgba(0, 0, 0, 0.8);
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;

          .video-audio-chat-video__wrapper {
            width: 280px;
            height: 245px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            object-fit: cover;
          }
        }
        &-maximized {
          display: flex;
          position: relative;
          width: 98%;
          height: 98%;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            max-height: 100%;
          }
          @media only screen and (min-width: 1025px) {
            max-width: 100%;
            max-height: 100%;
          }

          .video-audio-chat-video__wrapper {
            video {
              height: 100%;
              width: 100%;
            }

            min-width: 86px;
            min-height: 71px;
            position: relative;
            width: 100%;
            height: 100%;
            -ms-flex-item-align: center;
            align-self: center;
            -o-object-fit: cover;
            object-fit: cover;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            object-fit: cover;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);

            &:hover {
              box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.8);
            }
          }
        }
      }
    }
    &-agent {
      &-video {
        &-minimized {
          position: absolute;
          bottom: 11px;
          right: 11px;
          video {
            height: 65px !important;
            width: 86.67px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
            position: relative;
            overflow: hidden;
            padding: 0;
            border: none;
            flex-shrink: 0;
            background-color: rgb(0, 0, 0);
            background-clip: padding-box;
            cursor: pointer;
            position: relative;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            display: flex;
            z-index: 2;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        &-maximized {
          display: flex;
          position: absolute;
          width: 300px;
          height: 200px;
          bottom: 10px;
          right: 10px;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            max-height: 100%;
          }
          @media only screen and (min-width: 1025px) {
            max-width: 100%;
            max-height: 100%;
          }

          video {
            @media only screen and (max-width: 1024px) {
              max-width: 100%;
              max-height: 100%;
            }
            @media only screen and (min-width: 1025px) {
              max-width: 100%;
              max-height: 100%;
            }
            width: 100%;
            height: 100%;
            -ms-flex-item-align: center;
            align-self: center;
            -o-object-fit: cover;
            object-fit: cover;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            object-fit: cover;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
          }
          video:hover {
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }

  &-chat-messages {
    width: 100%;
    height: auto;
    max-width: 300px;
    align-self: center;
    margin-left: auto;
    margin: 0;
  }

  &-audio {
    position: relative;
    &-header {
      background: #275D73;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 6px;

      @media only screen and (max-width: 1024px) {
        background: #ffffff;
        border-radius: 0px;
      }
    }
    &-minimized {
      width: 280px;
      height: 245px;
    }
    &-maximized {
      width: 100%;
      height: 100%;
      @media only screen and (max-width: 1024px) {
        width: 280px;
        height: 245px;
      }
      @media only screen and (min-width: 1025px) {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &-local-audio {
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 100;

    &-header {
      background: #275D73;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
    &-small {
      height: 65px;
      width: 87px;
      background: #275D73;
    }
  }

  &__button-switch {
    position: absolute;
    top: 100px;
    right: 20px;
    width: 35px;
    height: 35px;
    background: none;
    border: none;
    z-index: 99999;
  }
}

.video-audio-chat-video-container--mobile {
  .video-audio-chat-video-agent-video-minimized {
    video {
      height: 87px !important;
      border-radius: 6px;
    }
  }

  .video-audio-chat-local-audio-small {
    width: 132px;
    height: 88px;
    border-radius: 6px;
  }
}
</style>
